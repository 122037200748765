<template>
  <h2 class="title-secondary bold-weight mb-3">Weiteres</h2>
  <p class="mb-2">Ergänzungen rund um Ihre Bestellung und besondere Wünsche Schreiben Sie bitte hier in das Freitexfeld. Wir melden Uns direkt bei Ihnen.</p>
  <textarea
  rows="5"
  class="form-control"
  placeholder="Ihre Ergänzungen"
  v-model="addit"></textarea>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  props: {
    additional: {
      require: false,
      type: String
    }
  },
  setup (props) {
    const addit = ref(props.additional)
    return {
      addit
    }
  }
}
</script>
